import React from 'react';
import {Helmet} from 'react-helmet';
import Footer from './Footer';
import GetInTouch from './GetInTouch';
import Header from './Header';
import '../../style/global.scss';

interface ILayoutProps {
   pageTitle: string;
}

const Layout: React.FC<ILayoutProps> = ({children, pageTitle}) => {
   React.useEffect(() => {
      const win = window as any;
      win.initializePage?.();
      return () => {
         win.pageInitialized = false;
      };
   }, []);

   return (
      <div className="sitewrapper">
         <Helmet>
            <title>{pageTitle}</title>
         </Helmet>
         <Header />
         {children}
         <GetInTouch />
         <Footer />
      </div>
   );
};

export default Layout;