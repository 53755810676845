const convertFormattedTimeToTimestamp = (formatted: string): number => {
   if (!formatted.length) {
      return 0;
   }

   const [minutes, seconds] = formatted.split(':');
   if (minutes === undefined || seconds === undefined) {
      console.warn(`Formatted time is not in valid format: ${formatted}`);
      return 0;
   }

   return parseInt(minutes) * 60 + parseInt(seconds);
};

export class VideoChapter {
   private _time: number;

   constructor(public readonly formattedTime: string, public readonly name: string, public readonly isChecked = true) {
      this._time = convertFormattedTimeToTimestamp(formattedTime);
   }

   get time() {
      return this._time;
   }
}