import {Link} from 'gatsby';
import React from 'react';
import {OutboundLink} from 'gatsby-plugin-google-analytics';

const Footer: React.FC<unknown> = () => {
   const year = new Date().getFullYear()
   return (
      <footer>
         <div className="container-fluid">
            <div className="row center-xs">
               <div className="col-xs-12">
                  <div className="footer-links">
                     <Link to="/terms_of_service">Terms of Service</Link>
                     <Link to="/privacy_policy">Privacy Policy</Link>
                     <Link to="/acceptable_use_policy">Acceptable Use Policy</Link>
                     <Link to="/investors">Investors</Link>
                  </div>
               </div>
            </div>
            <div className="row center-xs">
               <div className="col-xs-12 col-sm-6 copyright">
                  <p>Copyright © {year} Lingco Language Labs Inc. All rights reserved</p>
               </div>
            </div>
         </div>
      </footer>
   );
};

export default Footer;
