
import {Link} from 'gatsby';
import React from 'react';
import {OutboundLink} from 'gatsby-plugin-google-analytics';

const Header: React.FC<unknown> = () => {
   return (
      <header>
         <div className="container-fluid">
            <div className="row center-xs">
               <div className="col-xs-12 col-sm-10">
                  <div className="navbar">
                     <div className="row">
                        <div className="col-xs-2"><Link to="/"><img src="/img/logo-type.svg" className="logo" /></Link></div>
                        <div className="col-xs-8 text-center">
                           <ul className="nav-item"><Link to="/">
                              <li>Home</li>
                           </Link>
                           <li className="dropdown">
                              <a href="#" data-toggle="dropdown" className="dropdown-toggle">Platform</a>
                              <ul className="dropdown-menu">
                                 <li><Link to="/platform/personalized-learning">Personalized Learning</Link></li>
                                 <li><Link to="/platform/course-management">Course Management</Link></li>
                                 <li><Link to="/platform/engaging-content">Engaging Content</Link></li>
                                 <li><Link to="/platform/insightful-analytics">Insightful Analytics</Link></li>
                              </ul>
                           </li>
                           </ul>
                        </div>
                        <div className="col-xs-2">
                           <OutboundLink href="https://class.lingco.io/" target="_blank" rel="noreferrer">
                              <button className="white">Log In</button>
                           </OutboundLink>
                        </div>
                     </div>
                  </div>
                  <div className="navbar-mobile">
                     <div className="row">
                        <div className="col-xs-12">
                           <div className="logo"><Link to="/"><img src="/img/logo-type.svg" /></Link></div>
                           <div className="menu-button"><img src="/img/icons/icon-menu-btn.svg" /></div>
                           <ul style={{display: 'none'}} className="nav-item">
                              <li><Link to="/">Home</Link></li>
                              <li className="dropdown"><a href="#" data-toggle="dropdown"
                                 className="dropdown-toggle">Platform</a>
                              <ul className="dropdown-menu">
                                 <li><Link to="/platform/personalized-learning">Personalized Learning</Link>
                                 </li>
                                 <li><Link to="/platform/course-management">Course Management</Link></li>
                                 <li><Link to="/platform/engaging-content">Engaging Content</Link></li>
                                 <li><Link to="/platform/insightful-analytics">Insightful Analytics</Link></li>
                              </ul>
                              </li><OutboundLink href="https://class.lingco.io/" target="_blank" rel="noreferrer">
                                 <li><button className="white">Log In</button></li>
                              </OutboundLink>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </header>
   );
};

export default Header;
