import useScript from '@/hooks/useScript';
import React from 'react';
import {GlobalWindow} from '../types';

declare const window: GlobalWindow;

export interface IWistiaPlayer {
   play: () => void;
   pause: () => void;
   time: (timestamp: number) => void;
}

export interface IPlaytime {
   timestamp: number;
   formatted: string;
}

interface IWistiaVideo {
   videoId: string;
   onReady?: (video) => void;
   onSeek?: (currentTime: number) => void;
   onTimeChange?: (currentTime: number) => void;
}

const WistiaVideo: React.FC<IWistiaVideo> = ({videoId, onSeek, onReady, onTimeChange}) => {

   useScript(`https://fast.wistia.com/embed/medias/${videoId}.jsonp`);
   useScript('https://fast.wistia.com/assets/external/E-v1.js');

   // none of these variables need to be state - they have no impact
   // on anythign we'll render
   const currentTime = React.useRef<number>(0);
   const videoPlayer = React.useRef<any>();
   const eventHandlersBound = React.useRef<boolean>(false);

   // when the component is destroyed we'll let Wistia clean up after itself
   React.useEffect(() => {
      return () => {
         videoPlayer?.current?.remove();
      };
   }, []);

   React.useEffect(() => {
      if (!videoId) {
         return;
      }

      // Wistia video event handlers, used as React props
      const videoSeek = (timeStamp) => {
         onSeek && onSeek(timeStamp);
      };

      const handleOnReady = (video) => {
         bindEventHandlers();
         if (onReady) {
            onReady(video);
         }
      };

      // we'll use some helper methods to easily see which events we're
      // using from Wistia
      const bindEventHandlers = () => {
         if (videoPlayer.current && !eventHandlersBound.current) {
            videoPlayer.current.bind('seek', videoSeek);
            eventHandlersBound. current = true;
         }
      };

      const unBindEventHandlers = () => {
         if (videoPlayer.current && eventHandlersBound.current) {
            videoPlayer.current.unbind('seek', videoSeek);
            eventHandlersBound. current = false;
         }
      };

      // this useEffect block will be run on every re-render of this component
      // so we only want to initialize the player once, but rebind all the
      // event handlers every time to avoid stale state in parents
      if (videoPlayer.current) {
         bindEventHandlers();
      } else {
         const playerReadyId = setInterval(() => {
            if (!window.Wistia) {
               return;
            }

            const player = window.Wistia.api(videoId);
            if (player && player.ready()) {
               clearInterval(playerReadyId);
               videoPlayer.current = player;
               handleOnReady(player);
            }
         }, 50);
      }

      // Wistia's 'timechange' event only fires every 300ms, and it looks a bit
      // chopppy.  We'll do it ourselves so we can control the frequency
      const timeId = setInterval(() => {
         if (videoPlayer.current) {
            const time = videoPlayer.current.time();
            if (Math.abs(time - currentTime.current) > .1) {
               currentTime.current = time;
               if (onTimeChange) {
                  onTimeChange(time);
               }
            }
         }
      }, 100);

      return (() => {
         unBindEventHandlers();
         clearInterval(timeId);
      });
   });

   const innerHtml = `<div class="wistia_responsive_padding" style="padding: 56.25% 0 0 0; position: relative;">
      <div class="wistia_responsive_wrapper" style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;">
         <div class="wistia_embed wistia_async_${videoId} videoFoam=true" style="height: 100%; position: relative; width: 100%;">
            <div class="wistia_swatch" style="height: 100%; left: 0; opacity: 0; overflow: hidden; position: absolute; top: 0; transition: opacity 200ms; width: 100%;">
               <img src="https://fast.wistia.com/embed/medias/${videoId}/swatch" style="filter: blur(5px); height: 100%; object-fit: contain; width: 100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
            </div>
         </div>
      </div>
   </div>
   `;

   return (
      videoId && <div dangerouslySetInnerHTML={{__html: innerHtml}}></div>
   );
};

export default WistiaVideo;
