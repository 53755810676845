import * as React from 'react';
import {OutboundLink} from 'gatsby-plugin-google-analytics';

const GetInTouch: React.FC<unknown> = () => {
   return (
      <section className="contact">
         <div className="container-fluid">
            <div className="row center-xs">
               <div className="col-xs-12 col-sm-9 col-md-6">
                  <div className="title-content text-center">
                     <h5>Want to learn more?</h5>
                     <h1>Get in touch, we’d love to talk.</h1>
                     <p className="text-larger">Allow us the opportunity to show you how Lingco works.<br />With Lingco, you can transform your classroom.</p>
                     <OutboundLink href="https://calendly.com/d/ymc-c2t-4x8" target="_blank" rel="noreferrer"><button>Schedule a Demo</button></OutboundLink>
                  </div>
               </div>
            </div>
         </div>
         <div className="section-divider"></div>
      </section>
   );

};

export default GetInTouch;
