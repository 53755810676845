
import React from 'react';

interface ICheckbox {
   label?: string;
   isSelected: boolean;
   onChange?: () => void;
}

const Checkbox: React.FC<ICheckbox> = ({label, isSelected, onChange}) => (
   <span className="form-check">
      <label>
         <input
            type="checkbox"
            name={label}
            checked={isSelected}
            onChange={onChange}
            className="form-check-input"
         />
         {label}
      </label>
   </span>
);

export default Checkbox;