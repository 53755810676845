import React from 'react';
import {Link, PageProps} from 'gatsby';
import Layout from '@/components/Layout';
import WistiaVideo, {IWistiaPlayer} from '@/components/WistiaVideo';
import {VideoChapter} from '@/models/Video';
import VideoTableOfContents from '@/components/VideoTableOfContents';
import '../../style/demoVideo.scss';

const wistiaVideoId = '6pee0ozjqk';
const chaptersDefault = [
   new VideoChapter('0:00', 'Intro'),
   new VideoChapter('1:34', 'Embed & Share asdf asdf asdf'),
   new VideoChapter('2:26', 'Stats')
];

const DemoVideo: React.FC<PageProps> = () => {

   const videoRef = React.useRef<IWistiaPlayer>();
   const lastTimestanp = React.useRef<number>(0);
   const lastAutoPauseTimestamp = React.useRef<number>();

   const [chapters, setChapters] = React.useState<VideoChapter[]>(chaptersDefault);
   const [currentChapter, setCurrentChapter] = React.useState<VideoChapter>(chapters[0]);

   const currentChapterIndex = chapters.indexOf(currentChapter);
   const nextChapter = currentChapterIndex < chapters.length - 1 ? chapters[currentChapterIndex + 1] : null;

   const updateChaptersState = (updateChapters: VideoChapter[]) => {
      setChapters(updateChapters);
      setCurrentChapter(prevCurrentChapter => updateChapters.find(x => x.name === prevCurrentChapter.name));
   };

   const handleOnReady = (video => {
      videoRef.current = video;
   });

   const findCurrentChapter = (timestamp): VideoChapter => {
      for (let i = 0; i < chapters.length; i++) {
         const chapter = chapters[i];
         const nextChapterTime = i < chapters.length - 1 ? chapters[i + 1].time : Number.POSITIVE_INFINITY;

         if (timestamp >= chapter.time && timestamp <= nextChapterTime) {
            return chapter;
         }
      }
   };

   const handleOnTimeChange = (timestamp: number) => {
      const currentChapterFromTime = findCurrentChapter(timestamp);
      const justSeeked = Math.abs(lastTimestanp.current - timestamp) > .5;
      const resumedAfterAutoPause = timestamp - lastAutoPauseTimestamp.current < .5;
      lastTimestanp.current = timestamp;

      // if we programatically paused earlier (due to no more 'viewable' chapters) and then
      // the viewer resumed the video, we'll make the next chapter viewable and continue
      if (resumedAfterAutoPause) {
         lastAutoPauseTimestamp.current = undefined;
         if (!nextChapter.isChecked) {
            toggleChapterChecked(nextChapter);
            return;
         }
      }

      // if we've just transistioned to a different chapter we'll do some additional processing
      if (currentChapter.name !== currentChapterFromTime.name) {

         // if the viewer just scrubbed or jumped to a different spot in the video we'll
         // update the current chapter and make sure it's 'checked'
         if (justSeeked && !currentChapterFromTime.isChecked) {
            toggleChapterChecked(currentChapterFromTime);
            setCurrentChapter(currentChapterFromTime);

         // otherwise, if we've transistioned to a chapter that is unchecked we'll find the
         // next playable chapter and skip to that
         } else if (!currentChapterFromTime.isChecked) {
            const newChapterIndex = chapters.indexOf(currentChapterFromTime);
            for (let i = newChapterIndex; i < chapters.length; i++) {
               if (chapters[i].isChecked) {
                  playChapter(chapters[i]);
                  return;
               }
            }

            // if there's no other chapters to play we'll pause the video.
            // if the user clicks 'play' again we'll continue from that spot
            lastAutoPauseTimestamp.current = timestamp;
            videoRef.current.pause();

         // and if the next chapter is 'checked' we'll just continue playing
         } else {
            setCurrentChapter(currentChapterFromTime);
         }
      }
   };

   const toggleChapterChecked = (chapter: VideoChapter) => {
      const updatedChapters = chapters.map(x => x.name === chapter.name
         ? new VideoChapter(x.formattedTime, x.name, !x.isChecked)
         : x);
      updateChaptersState(updatedChapters);
   };

   const playChapter = (chapter: VideoChapter) => {
      setCurrentChapter(chapter);
      videoRef.current.time(chapter.time + .01);
   };

   return (
      <Layout pageTitle="Demo Video">
         <section className="demo-video">
            <div className="text-center">
               <h1>Demo Video</h1>
               <p className="text-larger">
                  Here's a nice little subheader. We should put some useful text here.
               </p>
            </div>
            <div className="video-toc-group-container">
               <div className="video-wrapper video-section">
                  <WistiaVideo
                     videoId={wistiaVideoId}
                     onReady={handleOnReady}
                     onTimeChange={handleOnTimeChange}
                  />
               </div>
               <div className="video-toc-wrapper video-section">
                  <div className="text-center">
                     <h3>
                        Topics
                     </h3>
                     <p className="text-left">Uncheck a topic to skip it or click on a topic title to jump to it.</p>
                  </div>
                  <VideoTableOfContents
                     chapters={chapters}
                     currentChapter={currentChapter}
                     chapterCheckToggled={toggleChapterChecked}
                     chapterClicked={playChapter}
                  />
               </div>
            </div>
         </section>
      </Layout>
   );
};

export default DemoVideo;
