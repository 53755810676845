import React from 'react';
import classnames from 'classnames';
import '../../style/global.scss';
import {VideoChapter} from '@/models/Video';
import Checkbox from './Checkbox';

interface IVideoTableOfContents {
   chapters: VideoChapter[],
   currentChapter?: VideoChapter,
   chapterClicked?: (chapter: VideoChapter) => void,
   chapterCheckToggled: (chapter: VideoChapter) => void
}

const VideoTableOfContents: React.FC<IVideoTableOfContents> = ({chapters, currentChapter, chapterCheckToggled, chapterClicked}) => {
   const renderChapters = () => {
      return chapters.map(c => {
         const isCurrent = c.name === currentChapter.name;
         return (
            <div key={c.name} className={classnames('toc-chapter-row-container', {active: isCurrent})}>
               <div className="toc-chapter-row">
                  <span><Checkbox isSelected={c.isChecked} onChange={() => chapterCheckToggled(c)}/></span>
                  <span className="toc-chapter-title" style={{fontWeight: isCurrent ? 'bold' : 'normal'}} onClick={() => chapterClicked(c)}>{c.name}</span>
               </div>
            </div>
         );
      });
   };

   return (
      <div className="video-toc">
         {renderChapters()}
      </div>
   );
};

export default VideoTableOfContents;